/********** Template CSS **********/
:root {
    --primary: #4294E3;
    --secondary: #8F12FD;
    --light: #F0F6FF;
    --dark: #262B47;
}


/*** Spinner ***/
/* #spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
} */


/*** Heading ***/
h1,
h2,
.fw-bold {
    font-weight: 700 !important;
}

h3,
h4,
.fw-semi-bold {
    font-weight: 600 !important;
}

h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}


/*** Gradient Text & BG ***/
.text-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-secondary-gradient {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.bg-secondary-gradient {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}


/*** Button ***/
.btn {
    transition: .5s;
}

.btn.btn-primary-gradient,
.btn.btn-secondary-gradient {
    position: relative;
    overflow: hidden;
    border: none;
    color: #FFFFFF;
    z-index: 1;
}

.btn.btn-primary-gradient::after,
.btn.btn-secondary-gradient::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .5s;
    z-index: -1;
    opacity: 0;
}

.btn.btn-primary-gradient,
.btn.btn-secondary-gradient::after {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.btn.btn-secondary-gradient,
.btn.btn-primary-gradient::after {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}

.btn.btn-primary-gradient:hover::after,
.btn.btn-secondary-gradient:hover::after {
    opacity: 1;
}


.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
    border: none;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}


/*** Navbar ***/
.navbar-light .navbar-nav .nav-link {
    position: relative;
    margin-right: 25px;
    padding: 45px 0;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: black;
    outline: none;
    transition: .5s;
}

.navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid;
    border-color: var(--light) transparent transparent transparent;
    transition: .5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover::before,
.navbar-light .navbar-nav .nav-link.active::before {
    top: 0;
}

.navbar-light .navbar-brand h1 {
    color: #FFFFFF;
    font-size: 25px;
    font-weight: normal;
   
}

@media (max-width: 767px) {
    .navbar-light .navbar-brand h1 {
    font-size: 18px;
    }

  

    .hero-header{
        width: 90%;
    }

    .abouttext{
        text-align: center;
    }

    .featurecircle{
        margin-left: 40%;
    }

    .featuretitle{
        text-align: center;
    }

    .featuredescription{
        text-align: justify;
    }

    .contactdescription{
        text-align: justify;
    }
    .footertitle{
        text-align: center;
    }

    .footertitle a{
        margin-left: 40%;
    }
    
   

    .btn1{
        text-align: center;
    }
}

.contactdescription{
    text-align: justify;
}

.navbar-light .navbar-brand img {
    max-height: 60px;
    transition: .5s;
}

.sticky-top.navbar-light .navbar-brand img {
    max-height: 45px;
}

@media (max-width: 991.98px) {
    .sticky-top.navbar-light {
        position: relative;
        background: #FFFFFF;
    }

    .navbar-light .navbar-collapse {
        margin-top: 15px;
        border-top: 1px solid #DDDDDD;
    }

    .navbar-light .navbar-nav .nav-link,
    .sticky-top.navbar-light .navbar-nav .nav-link {
        padding: 10px 0;
        margin-left: 0;
        color: var(--dark) !important;
    }

    .navbar-light .navbar-nav .nav-link::before {
        display: none;
    }

    .navbar-light .navbar-nav .nav-link:hover,
    .navbar-light .navbar-nav .nav-link.active {
        color: var(--primary) !important;
    }

    .navbar-light .navbar-brand h1 {
        background: linear-gradient(to bottom right, var(--primary), var(--secondary));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .navbar-light .navbar-brand img {
        max-height: 45px;
    }
}

@media (min-width: 992px) {
    .navbar-light {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;
    }
    
    .sticky-top.navbar-light {
        position: fixed;
        background: #FFFFFF;
    }

    .sticky-top.navbar-light .navbar-nav .nav-link::before {
        border-top-color: var(--primary);
    }

    .sticky-top.navbar-light .navbar-brand h1 {
        background: linear-gradient(to bottom right, var(--primary), var(--secondary));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


/*** Hero Header ***/
.hero-header {
    margin-bottom: 6rem;
    padding: 16rem 0 0 0;
    /* background:
         url(../img/img/bg-circle.png),
        url(../img/img/bg-triangle.png),
        url(../img/img/bg-bottom.png), 
        linear-gradient(to bottom right, var(--primary), var(--secondary));
    background-position:
        left 0px top 0px,
        right 0px top 50%,
        center bottom;
    background-repeat: no-repeat; */
}

@media (max-width: 991.98px) {
    .hero-header {
        padding: 6rem 0 9rem 0;
    }
}


/*** Feature ***/
.feature-item {
    transition: .5s;
}

.feature-item:hover {
    margin-top: -15px;
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .08);
}


/*** Pricing ***/
.pricing .nav {
    padding: 2px;
}

.pricing .nav-link {
    padding: 12px 30px;
    font-weight: 500;
    color: var(--dark);
    background: #FFFFFF;
}

.pricing .nav-item:first-child .nav-link {
    border-radius: 30px 0 0 30px;
}

.pricing .nav-item:last-child .nav-link {
    border-radius: 0 30px 30px 0;
}

.pricing .nav-link.active {
    color: #FFFFFF;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}


/*** Screenshot ***/
.screenshot-carousel {
    position: relative;
    width: 253px;
    height: 500px;
    padding: 15px;
    margin-right: 30px;
}

.screenshot-carousel::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../img/img/screenshot-frame.png) center center no-repeat;
    background-size: 253px 500px;
    z-index: 1;
}

.screenshot-carousel .owl-item img {
    position: relative;
    width: 223px;
    height: 470px;
}

.screenshot-carousel .owl-dots {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.screenshot-carousel .img-fluid{
    height: 475px;
    width: 218px;
}

.screenshot-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 5px 0;
    width: 15px;
    height: 15px;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-radius: 15px;
    transition: .5s;
}

.screenshot-carousel .owl-dot::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    top: 5px;
    left: 5px;
    background: #FFFFFF;
    border-radius: 5px;
}

.screenshot-carousel .owl-dot.active {
    box-shadow: 0 0 10px var(--dark);
}

/** Desktop Slide **/

/*** Screenshot ***/
.screenshot-carousel1 {
    position: relative;
    width: 460px;
    height: 340px;
    padding: 15px;
    
    
}

.screenshot-carousel1::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: 300px 500px;
    background: url(../img/img/Laptopframe1.png) center center no-repeat;
   
    z-index: 1;
}

.screenshot-carousel1 .owl-item img {
    
    position: relative;
    width: 400px;
    height: 400px;
}

.screenshot-carousel1 .owl-dots {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.screenshot-carousel1 .img-fluid{
    
    width: 440px;
    height: 275px;
}

.screenshot-carousel1 .owl-dot {
    position: relative;
    display: inline-block;
    margin: 5px 0;
    width: 15px;
    height: 15px;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-radius: 15px;
    transition: .5s;
}

.screenshot-carousel1 .owl-dot::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    top: 5px;
    left: 5px;
    background: #FFFFFF;
    border-radius: 5px;
}

.screenshot-carousel1 .owl-dot.active {
    box-shadow: 0 0 10px var(--dark);
}
@media (max-width: 768px) {
    .screenshot-carousel1 {
        width: 350px;
        height: 200px;
        
    }
    
    .screenshot-carousel1::before {
       
        background-size: 400px 200px;
       
    }

    .screenshot-carousel1 img{
        height: 295px;
        background-size: cover;
    }

    .screenshot-carousel1 .img-fluid {
       
        margin-left: 40px; 
        width: 78%;
        height: 155px;
        
        /* Ensures the image stays within the frame */
    }
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item .testimonial-item * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.testimonial-carousel .owl-item.center .testimonial-item h5,
.testimonial-carousel .owl-item.center .testimonial-item p {
    color: #FFFFFF !important;
}

.testimonial-carousel .owl-nav {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    margin: 0 12px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-radius: 60px;
    font-size: 18px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}


/*** Footer ***/
.footer {
   
  
    /* background:
         url(../img/img/bg-circle.png),
        url(../img/img/bg-triangle.png),
        url(../img/img/bg-top.png),
        linear-gradient(to bottom right, var(--primary), var(--secondary));
    background-position:
        left 0px bottom 0px,
        right 0px top 50%,
        center top;
    background-repeat: no-repeat; */
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid rgba(256, 256, 256, .1);
    border-radius: 40px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    color: var(--light);
    font-weight: normal;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 14px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.navbar {
    background-color:#cbdcf7;
}

.navbar-nav .nav-link{
    color: black;

}

.homefirsttitle{
    font-size: 30px;
    text-align: left;
    font-weight: normal;
    
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.homefirstdescription {
    font-size: 17px;
    text-align: left;
}