.rowtablechart{
    display: flex;
    flex-direction: row;
}

.tablechartmbile{
    width: 60%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-left: 40px;
}

/*Appoint form page*/
.appointment-form{
    padding-left: 40%;
}

@media (max-width: 768px) {
    .rowtablechart{
        display: flex;
        flex-direction: column;
    }

    .tablechartmbile{
        margin-left: 0%;
        overflow-x: auto;
        width: 100%;
    }

    .endateAdmind{
        padding-top: 5px;
    }

    .endabutton{
        margin-top: 10px;
    }

    /*Appoint form page*/
    .appointment-form{
        margin-left: 70px;
        padding-left: 0;
    }


   
    
    .table-container {
        padding: 0 15px; /* Extra space around the table in mobile view */
      }
    
      #adminTable th, #adminTable td {
        padding: 15px; /* Extra space inside the table cells */
      }
      
}

.table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  #adminTable {
    width: 100%;
    border-collapse: collapse;
    -webkit-box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
  }
  
  #adminTable th, #adminTable td {
    padding: 10px;
    text-align: left;
    white-space: nowrap;
  }