.tag-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .tag {
    background-color: #e1e1e1;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 5px;
    font-size: 14px;
  }
  
  .tag-delete {
    color: #888;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .tag-delete:hover {
    color: #000;
  }
  
  .tags-container input {
    border: none;
    outline: none;
    flex-grow: 1;
    margin: 2px;
    padding: 5px;
    font-size: 14px;
  }
  