/* Media query for mobile view */
@media (max-width: 768px) {
    .mobile-footer {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #4576d1;
      padding: 10px;
      z-index: 1000;
    }
  
    .mobile-footer a {
      color: white;
      font-size: 24px;
    }
  
  
  
    .show-sidebar {
      display: block !important;
      width: 250px !important;
      position: fixed !important;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #4576d1 !important;
      z-index: 1000;
      overflow-x: hidden;
      transition: transform 0.5s ease !important;
      transform: translateX(0) !important;
    }
  
    /* Sidebar hidden state on mobile */
    #layoutSidenav_nav {

      transform: translateX(-250px);
      transition: transform 0.5s ease;
    }

    .close-sidebar-icon {
        display: block;
        position:static;
        top: 10px;
        text-align: right;
        margin-right: 20px;
        color: white;
        font-size: 24px;
        cursor: pointer;
      }

      .navicontop{
        display: none;
      }

      .navbar-expand a{
        font-size: 15px;
        text-align: center;
      }
  }

  @media (min-width: 769px) {
    .close-sidebar-icon {
      display: none;
    }
  }

