/* Add this CSS to style your table */
table#listPatients2 {
    width: 100%;
    table-layout: fixed; /* Ensures all columns are the same width */
  }
  
  table#listPatients2 th,
  table#listPatients2 td {
    padding: 8px; /* Add some padding for better spacing */
    text-align: left; /* Align text to the left */
  }
  
  table#listPatients2 th:nth-child(2),
  table#listPatients2 td:nth-child(2) {
    width: 15%; /* Adjust the width for Doctor Name */
  }
  
  table#listPatients2 th:nth-child(4),
  table#listPatients2 td:nth-child(4) {
    width: 25%; /* Adjust the width for Email */
  }
  
  /* You can also target specific columns by index, e.g., nth-child(3) for the third column */
  