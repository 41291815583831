@media (max-width: 768px) {
.admin_form{
    width:100%;
}

.buttoncenter{
    margin-left: 100px;
    width: 120px;
}
/*Logo */
.logosubmit{
    align-items: center;
    margin-top: 50px;
}
.logobutton{
    text-align: center;
    width: 150px;
}
/*Address*/
.addresssubmit{
    width: 160px;
}
.AddressForm label{
    font-weight: bold;
}
/*Social Media*/
.socialMediaform label{
    font-weight: bold;
}

.socialmediabutton {
    width :150px;
}



#adminTable{
    width: auto;
    overflow: auto;
}

#appointmentsTable{
    width: auto;
    overflow: auto;
}

#listPatients{
    width: auto;
    overflow: auto;
}
#listPatients1{
    width: auto;
    overflow: auto;
}
#listPatients3{
    width: 200%;
    overflow: auto;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.date-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allows elements to wrap */
    gap: 0; /* Reset gap to avoid extra spacing */
}

.filter {
    width: 100%; /* Full width for each filter in mobile view */
    margin-bottom: 10px; /* Space between rows in mobile view */
}

.filter:nth-child(1), .filter:nth-child(2) {
    width:42%; /* Keep 'From' and 'To' fields on the same row */

}

.filter:nth-child(2) {
  
}

.filter:nth-child(3) {
    width: 100%; /* 'Select Doctor' field on a new row */
}

}

/*Logo */
@media only screen and (min-width: 1200px){
.logosubmit{
    margin-left: 40%;
    margin-top: 50px;
}
/*Address*/
.AddressForm{
    margin-left: 20%;
    margin-top: 50px;
}

.AddressForm label{
    font-weight: bold;
}


/*Social Media*/
.socialMediaform{
    margin-left: 40%;
    margin-top: 50px;
}
.socialMediaform label{
    font-weight: bold;
}

}

