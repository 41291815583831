.hospital-logo{
    max-width: 100%;
    width: 100px; 
    height: auto;
    object-fit: cover; 
    border-radius: 10px;
}


.footerbill {
    height: 100px;
}

.social-icons {
    padding-top: 40px;
}