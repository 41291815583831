@media only screen and (min-width: 1200px){
.containerAddPatient{
    margin-left: 150px;

}

.formtype{
    display: flex;
}
.Addpatientcard{
    width: 500px;
}

.radioadd{
    margin-left: 0;
}

}

.containerAddPatient{
    margin-top: 100px;
}

.radioadd{
    margin-left: 450px;
}

