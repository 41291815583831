.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 80%;
  }
  
  .modal-content h4{
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .modal-content p{
    font-weight: normal;
    margin-top: 10px;
  }

  .modal-content button{
    border-color: blue;
    width: 100px;
    border-radius: 5px;
  }

  .modal-content .alert-message {
    font-size: 12px;
    color: blueviolet;
  }