.container3{
    width: 500px;
    margin-left: 400px;
}

.multidata{
    margin-left: 100px;
}

.input-container {
    position: relative;
  }
  
  .input-container .form-control {
    padding-right: 40px; /* Adjust padding to make room for the icon */
  }
  
  .iconadd {
    color:blue;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    pointer-events: none; /* Make sure the icon doesn't block input interactions */
  }
  