/* General styling for the table rows */
.table-row1 td {
    padding: 8px;
    vertical-align: middle;
  }
  
  /* Adjust width for each column */
  .select-cell1 {
    width: 10%; /* Adjust as needed */
  }
  
  .select-cell2 {
    width: 20%; /* Adjust as needed */
  }

  .session-cell1 {
    width: 50%; /* Give more space for session column */
  }
  
  .session-quantity-cell {
    width: 50%; /* Give more space for quantity column */
  }

  
  
  .action-cell {
    width: 10%; /* Reduce space for action column */
    text-align: center;
  }
  
  /* Nested table styling */
  .nested-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .nested-table th, .nested-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
  }
  