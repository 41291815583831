@media only screen and (max-width: 767px) {
   
    .navbar-home {
      
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
    }
}

.navbar-home{
    position: fixed;
}