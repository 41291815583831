.align-content-center{
    width: 25%;
    align-items: center;
    position: sticky;
    left: 38%;
}

.text-center1{
    margin-top: 30px;
    text-align: center;
}

.text-center2 p{
    top: 10px;
    position: static;
    left: 20px;
}


@media (max-width: 768px){
    .align-content-center {
        position: fixed;
      
        left: 10%;
        width: 80%;
    }

    .form-container2 {
        max-width: 100%;
    }

    .image-container{
        display: none;
    }

    
    
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    max-width: 90%;
    height: auto;
    border-radius: 10px;
}

@media only screen and (min-width: 1025px) {
.form-container2 {
    margin-left: 150px;
    flex: 1;
    max-width: 400px; 
    width: 50px;
}
}

.text-center1 {
    text-align: center;
}





.container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.form-container1 {
    flex: 1;
    max-width: 400px; /* Adjust the maximum width as needed */
    margin-right: 20px;
    width: 50px;
}

.image-container1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}


.image-container1 img {
    max-width: 60%;
    height: auto;
    border-radius: 10px;
}


.align-content-center1 {
    width: 25%;
    align-items: center;
    position: sticky;
    left: 10%;
}

.text-center1 {
    margin-top: 30px;
    text-align: center;
}

@media (max-width: 768px) {
    .align-content-center1 {
        position: fixed;
        
        left: 10%;
        width: 80%;
    }

    .form-container1 {
        max-width: 100%;
        margin-right: 0;
    }

    .image-container1 {
        display: none;
    }

    .image-container {
        display: none;
    }
}