.table-row td {
    padding: 8px;
    vertical-align: middle;
  }
  .select-cell {
    width: 100%;
  }
  .quantity-cell, .price-cell, .amount-cell, .action-cell {
    min-width: 50px;
  }
  .action-cell {
    text-align: center;
  }

  .amount-cell {
    text-align: right;
  }

  .price-cell {
    padding-left: 60px;
  }